import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    TextField,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';

import { api } from '../../../../services';

const MODAL_HEIGHT = '65vh';

const CategoryEditDialog = ({ open, onClose, categories, onSave }) => {
    const [editedCategories, setEditedCategories] = useState([]);
    const [errors, setErrors] = useState({});
    const listRef = React.useRef(null);

    const theme = useTheme();

    const styles = {
        devValue: { color: theme.palette.project.colors[1], fontWeight: 'bold' },
        testValue: { color: theme.palette.project.colors[0], fontWeight: 'bold' },
        tableHeader: {
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            padding: 1,
            position: 'sticky',
            top: 0,
            zIndex: 2,
        },
        scrollContainer: {
            height: MODAL_HEIGHT,
            overflowY: 'auto',
        },
    };

    useEffect(() => {
        if (categories.length > 0 && editedCategories.length === 0) {
            setEditedCategories([...categories]);
        }
    }, [categories, editedCategories]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const updatedList = Array.from(editedCategories);
        const [movedItem] = updatedList.splice(result.source.index, 1);
        updatedList.splice(result.destination.index, 0, movedItem);
        setEditedCategories(updatedList);
    };

    const handleFieldChange = (index, field, value) => {
        setEditedCategories((prevCategories) =>
            prevCategories.map((category, i) => (i === index ? { ...category, [field]: value } : category)),
        );

        if (field === 'name') {
            setErrors((prevErrors) => ({ ...prevErrors, [index]: value.trim() === '' }));
        }
    };

    const addCategory = () => {
        setEditedCategories((prevCategories) => {
            const updatedCategories = [
                ...prevCategories,
                {
                    id: null,
                    name: '',
                    level_1_dev: '',
                    level_1_test: '',
                    level_2_dev: '',
                    level_2_test: '',
                    level_3_dev: '',
                    level_3_test: '',
                },
            ];

            setTimeout(() => {
                if (listRef.current) {
                    listRef.current.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
                }
            }, 100);

            return updatedCategories;
        });
    };

    const removeCategory = (index) => {
        setEditedCategories((prevCategories) => prevCategories.filter((_, i) => i !== index));

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[index];
            return newErrors;
        });
    };

    const handleSave = () => {
        let hasError = false;
        let newErrors = {};

        editedCategories.forEach((category, index) => {
            if (!category.name.trim()) {
                newErrors[index] = true;
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            return;
        }

        const updatedCategories = editedCategories.map((category, index) => ({
            ...category,
            display_order: index,
        }));

        onSave(updatedCategories);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
            <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>Edit Legend</DialogTitle>

            <Box sx={styles.tableHeader}>
                <Typography sx={{ width: '30%' }} align='center'></Typography>
                <Typography align='center' sx={{ width: '36%', fontWeight: 'bold', ...styles.testValue }}>
                    Testing
                </Typography>
                <Typography align='center' sx={{ width: '36%', fontWeight: 'bold', ...styles.devValue }}>
                    Development
                </Typography>
                <Typography sx={{ width: '5%' }}></Typography>
            </Box>
            <Box sx={styles.tableHeader}>
                <Typography sx={{ width: '5%' }} align='center'></Typography>
                <Typography sx={{ width: '25%', fontWeight: 'bold' }} align='center'>
                    Category Name
                </Typography>
                {[1, 2, 3].map((level) => (
                    <Typography align='center' key={`level_${level}_test`} sx={{ width: '12%', ...styles.testValue }}>
                        Level {level}
                    </Typography>
                ))}
                {[1, 2, 3].map((level) => (
                    <Typography align='center' key={`level_${level}_dev`} sx={{ width: '12%', ...styles.devValue }}>
                        Level {level}
                    </Typography>
                ))}
                <Typography sx={{ width: '5%' }}></Typography>
            </Box>
            <Divider sx={{ marginBottom: 0 }} align='center' />

            <DialogContent sx={styles.scrollContainer} ref={listRef}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId='categories'>
                        {(provided) => (
                            <List {...provided.droppableProps} ref={provided.innerRef}>
                                {editedCategories.map((category, index) => (
                                    <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                        {(provided) => (
                                            <ListItem
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                    padding: 1,
                                                    border: `1px solid ${theme.palette.divider}`,
                                                    borderRadius: 2,
                                                    marginBottom: 1,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '5%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <IconButton
                                                        disableRipple
                                                        disableFocusRipple
                                                        sx={{
                                                            cursor: 'grab',
                                                            '&:hover': { backgroundColor: 'transparent' },
                                                        }}
                                                    >
                                                        <DragIndicatorIcon />
                                                    </IconButton>
                                                </Box>
                                                <TextField
                                                    value={category.name}
                                                    onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                                                    size='small'
                                                    sx={{ width: '25%' }}
                                                    error={!!errors[index]}
                                                />
                                                {[1, 2, 3].map((level) => (
                                                    <TextField
                                                        key={`level_${level}_test`}
                                                        sx={{ width: '12%', margin: '0 2px' }}
                                                        value={category[`level_${level}_test`]}
                                                        onChange={(e) =>
                                                            handleFieldChange(
                                                                index,
                                                                `level_${level}_test`,
                                                                e.target.value,
                                                            )
                                                        }
                                                        size='small'
                                                    />
                                                ))}
                                                {[1, 2, 3].map((level) => (
                                                    <TextField
                                                        key={`level_${level}_dev`}
                                                        sx={{ width: '12%', margin: '0 2px' }}
                                                        value={category[`level_${level}_dev`]}
                                                        onChange={(e) =>
                                                            handleFieldChange(
                                                                index,
                                                                `level_${level}_dev`,
                                                                e.target.value,
                                                            )
                                                        }
                                                        size='small'
                                                    />
                                                ))}
                                                <Box sx={{ width: '5%' }}>
                                                    <IconButton edge='end' onClick={() => removeCategory(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </ListItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
            </DialogContent>

            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <IconButton onClick={addCategory} sx={{ padding: 1 }}>
                    <AddIcon />
                </IconButton>
            </Box>

            <DialogActions sx={{ marginBottom: 2, marginRight: 2 }}>
                <Button onClick={onClose} color='secondary'>
                    Cancel
                </Button>
                <Button onClick={handleSave} color='primary' variant='contained'>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    categories: state.data[api.endpoints.projectLegend]?.data || [],
});

export default connect(mapStateToProps)(CategoryEditDialog);
