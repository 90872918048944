import { annotation } from './annotation';
import { auth } from './auth';
import { data } from './data';
import { dataHealth, health } from './health';
import { qbuilder } from './qbuilder';
import { stats } from './stats';

export const api = {
    auth,
    data,
    health,
    dataHealth,
    qbuilder,
    annotation,
    stats,

    endpoints: {
        // Core
        dsReRun: 'api/qbuilder/rerun/',
        dsCount: 'api/ds-tasks/count',

        celery: 'api/celery/',
        annList: 'api/annotations/',
        annRemove: 'api/annotations/',
        annVcRestore: 'api/annotations/restore',
        annProcess: 'api/annotations/processing',
        annStatus: 'api/annotations/status',
        annWarningCodes: 'api/annotations/warning_codes',
        annUsers: 'api/annotations/users',
        annStatistics: 'api/annotations/statistics',
        annReview: 'api/annotations/review',
        attrStatistics: 'api/data/annotations/statistics/attributes/',
        segmentStatistics: 'api/data/annotations/statistics/segments/',
        labelTree: 'api/data/annotations/tree/labels/',
        segmentTree: 'api/data/annotations/tree/segments/',

        information: 'api/information/',
        dataProject: 'api/data/projects/',
        project: 'api/projects/',
        projectLegend: 'api/projects_legends/',
        projectsHomeGraph: 'api/projects/home/graph',

        // Data
        dsTasks: 'api/ds-tasks/',
        dsComplete: 'api/data/ds-tasks/complete',

        dsMatched: 'api/data/ds-tasks/matched',
        anTasks: 'api/data/annotations/tasks/',
        annDetails: 'api/data/annotations/detail',
        annAttributes: 'api/data/annotations/attributes',
        annSearch: 'api/data/annotations/search',
        qbuilderEvaluate: 'api/data/qbuilder/evaluate',
    },
};
