import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../../actions';
import { api } from '../../../../services';
import { CustomLegend } from './CustomLegend';

export const PAGE_HEADER_HEIGHT = 86;
export const DEFAULT_BODY_HEIGHT = 280;
const description = 'Data needed';

const ProjectLegend = (props) => {
    const theme = useTheme();
    const { categories, fetchCategories } = props;

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    const styles = {
        legend: {
            color: theme.palette.common.black,
            padding: theme.spacing(1),
            overflow: 'visible',
            height: `calc(100vh - ${DEFAULT_BODY_HEIGHT - PAGE_HEADER_HEIGHT}px)`,
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        header: {
            textAlign: 'center',
            padding: theme.spacing(1),
        },
        headerText: {
            fontWeight: 'bold',
        },
        card: {
            margin: '8px',
        },
        levelsHeader: {
            display: 'flex',
            justifyContent: 'space-around',
            fontWeight: 'bold',
            marginTop: theme.spacing(1),
        },
        levelValues: {
            display: 'flex',
            justifyContent: 'space-around',
        },
        levelColumn: {
            width: '33%',
            textAlign: 'center',
        },
        devValue: {
            color: theme.palette.project.colors[1],
            fontSize: '12px',
            fontWeight: 'bold',
        },
        testValue: {
            color: theme.palette.project.colors[0],
            fontSize: '12px',
            fontWeight: 'bold',
        },
        separator: {
            height: 1,
            margin: '0 8px 4px 8px',
            borderBottom: '0px',
            borderColor: theme.palette.common.lightgray,
            borderStyle: 'solid',
        },
        titles: {
            fontSize: '12px',
        },
        level: {
            fontSize: '11px',
        },
        description: {
            fontSize: '12px',
            fontWeight: 'bold',
        },
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.legend}>
                <Box sx={styles.header}>
                    <Typography variant='body2' sx={styles.description}>
                        {description}
                    </Typography>
                </Box>
                <CustomLegend padding={'4px 0'} />
                <Box sx={styles.levelsHeader}>
                    <Box sx={styles.levelColumn}>
                        <Typography variant='body2' sx={styles.level}>
                            Level 1
                        </Typography>
                    </Box>
                    <Box sx={styles.levelColumn}>
                        <Typography variant='body2' sx={styles.level}>
                            Level 2
                        </Typography>
                    </Box>
                    <Box sx={styles.levelColumn}>
                        <Typography variant='body2' sx={styles.level}>
                            Level 3
                        </Typography>
                    </Box>
                </Box>
                {categories.map((category) => (
                    <Box key={category.id} sx={styles.card}>
                        <hr style={styles.separator} />
                        <Typography variant='subtitle1' fontWeight='bold' textAlign='center' sx={styles.titles}>
                            {category.name}
                        </Typography>
                        <Box sx={styles.levelValues}>
                            {[1, 2, 3].map((level) => (
                                <Box key={level} sx={styles.levelColumn}>
                                    <Typography variant='body2' sx={styles.testValue}>
                                        {category[`level_${level}_test`] || '-'}
                                    </Typography>
                                    <Typography variant='body2' sx={styles.devValue}>
                                        {category[`level_${level}_dev`] || '-'}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))}
                <Box sx={styles.card}>
                    <hr style={styles.separator} />
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        categories: state.data[api.endpoints.projectLegend]?.data || [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategories: () => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.projectLegend })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectLegend);
