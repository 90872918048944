import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import EditIcon from '@mui/icons-material/Edit';
import { Box, CardActionArea, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { actions } from '../../../actions';
import { api } from '../../../services';
import ProjectDetailsPanel from '../ProjectDetails';
import { getPanelUri } from '../index';
import CategoryEditDialog from './components/CategoryEditDialog';
import ProjectCard from './components/ProjectCard';
import ProjectLegend, { DEFAULT_BODY_HEIGHT, PAGE_HEADER_HEIGHT } from './components/ProjectLegend';
import ProjectCreationPanel from './panels/ProjectCreation/ProjectCreationPanel';
import './styles/styles.css';

const HEADER_HEIGHT = 55;
const GRAPH_HEIGHT = 330;
const MASONRY_BREAKPOINTS = {
    default: 5,
    1600: 4,
    1400: 3,
    1100: 2,
    700: 1,
};

const getCurrentNbColumns = () => {
    const width = window.innerWidth;
    let currentColumns = MASONRY_BREAKPOINTS.default;
    Object.entries(MASONRY_BREAKPOINTS).forEach(([breakpoint, columns]) => {
        if (width <= parseInt(breakpoint) && currentColumns === MASONRY_BREAKPOINTS.default) {
            currentColumns = columns;
        }
    });
    return currentColumns;
};

function ProjectPanel(props) {
    const { fetchProjects, projects, clearProjects, updateCategories } = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const [columnCount, setColumnCount] = useState(getCurrentNbColumns());
    const [graphMaxLength, setGraphMaxLength] = useState(0);
    const [graphFontSize, setGraphFontSize] = useState('1vw');
    const [editOpen, setEditOpen] = useState(false);

    const styles = {
        paper: {
            width: '100%',
            position: 'relative',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            marginBottom: '4px',
        },
        action: {
            position: 'absolute',
            top: 0,
            right: 0,
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        outPaper: { [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(4) } },
        title: {
            fontWeight: 'bold',
            paddingTop: theme.spacing(3),
            paddingBottom: '4px',
            textAlign: 'center',
            height: '3rem',
        },
        topPage: { margin: '0 16px' },
        subTitle: { textAlign: 'center', marginBottom: theme.spacing(2) },
        queryCard: { margin: theme.spacing(3), backgroundColor: theme.palette.common.verylightgray },
    };

    const getFontSize = useCallback(() => {
        switch (columnCount) {
            case 5:
                return '0.6vw';
            case 4:
                return '0.7vw';
            case 3:
                return '0.8vw';
            case 2:
                return '1.1vw';
            case 1:
                return '1.7vw';
            default:
                return '1vw';
        }
    }, [columnCount]);

    const getMaxLabelLength = useCallback(() => {
        switch (columnCount) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                return 15;
            default:
                return 18;
        }
    }, [columnCount]);

    useEffect(() => {
        setGraphFontSize(getFontSize());
        setGraphMaxLength(getMaxLabelLength());
    }, [getFontSize, getMaxLabelLength]);

    const openProject = (project) => {
        navigate(getPanelUri(<ProjectDetailsPanel />, { id: project.id }));
    };

    const handleSave = (updatedCategories) => {
        updateCategories(updatedCategories);
    };

    const normalizeProjects = useCallback((projects, columnCount) => {
        if (!Array.isArray(projects)) {
            return [projects];
        }
        const sortedProjects = [...projects];
        const columns = Array.from({ length: columnCount }, () => ({ projects: [], height: 0 }));
        sortedProjects.forEach((project) => {
            const projectHeight = HEADER_HEIGHT + (project.graph_data?.length || 0) * GRAPH_HEIGHT;
            let smallestColumnIndex = 0;
            let smallestColumnHeight = columns[0].height;
            for (let i = 1; i < columns.length; i++) {
                if (columns[i].height < smallestColumnHeight) {
                    smallestColumnIndex = i;
                    smallestColumnHeight = columns[i].height;
                }
            }
            columns[smallestColumnIndex].projects.push(project);
            columns[smallestColumnIndex].height += projectHeight;
        });

        const result = [];

        while (columns.some((column) => column.projects.length > 0)) {
            columns.forEach((column) => {
                result.push(column.projects.length > 0 ? column.projects.shift() : null);
            });
        }
        return result;
    }, []);

    const handleNewProject = () => {
        navigate(getPanelUri(<ProjectCreationPanel />));
    };

    useEffect(() => {
        clearProjects();
        fetchProjects();
    }, [fetchProjects, clearProjects]);

    useEffect(() => {
        const handleResize = () => {
            setColumnCount(getCurrentNbColumns());
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getWidth = useCallback(
        (option = 'panel') => {
            const columnWidth = 100 / (columnCount + 1);
            const unitWidth = columnWidth / 3;

            const legendWidth = unitWidth * 2;

            if (option === 'legend') {
                return `${legendWidth}%`;
            }

            return `${100 - legendWidth}%`;
        },
        [columnCount],
    );

    return (
        <Box sx={styles.outPaper}>
            <Paper style={styles.paper}>
                <Box sx={styles.topPage}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <Typography variant='h5' sx={styles.title}>
                            Data Readiness
                        </Typography>
                    </Box>
                </Box>
                <Box style={styles.action}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Tooltip title='Edit Legend'>
                            <IconButton onClick={() => setEditOpen(true)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='New Project'>
                            <IconButton onClick={handleNewProject}>
                                <CreateNewFolderIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Box
                        sx={{
                            width: getWidth(),
                            margin: theme.spacing(2, 0),
                            height: `calc(100vh - ${DEFAULT_BODY_HEIGHT - PAGE_HEADER_HEIGHT}px)`,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                        }}
                    >
                        <Masonry
                            breakpointCols={MASONRY_BREAKPOINTS}
                            className='masonry-grid'
                            columnClassName='masonry-grid_column'
                        >
                            {normalizeProjects(projects, columnCount)?.map((project, index) => (
                                <div key={index} className='masonry-item'>
                                    {project && (
                                        <CardActionArea
                                            onClick={() => openProject(project)}
                                            sx={{ borderRadius: 2, marginBottom: theme.spacing(2) }}
                                        >
                                            <Box sx={{ aspectRatio: '1/1', width: '100%' }}>
                                                <ProjectCard
                                                    project={project}
                                                    graphMaxLength={graphMaxLength}
                                                    graphFontSize={graphFontSize}
                                                />
                                            </Box>
                                        </CardActionArea>
                                    )}
                                </div>
                            ))}
                        </Masonry>
                    </Box>
                    <Box sx={{ width: getWidth('legend'), padding: theme.spacing(2, 0) }}>
                        <ProjectLegend />
                    </Box>
                </Box>
            </Paper>
            <CategoryEditDialog open={editOpen} onClose={() => setEditOpen(false)} onSave={handleSave} />
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        projects: state.data[api.endpoints.project]?.data || [],
        graphs: state.data[api.endpoints.projectsHomeGraph]?.data || [],
        isLoading: state.data[api.endpoints.project]?.isFetching || false,
        categories: state.data[api.endpoints.projectLegend]?.data || [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjects: () => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.project })),
        clearProjects: () => dispatch(actions.api.data.reset({ endpoint: api.endpoints.project })),
        fetchHomeGraph: () => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.projectsHomeGraph })),
        clearHomeGraph: () => dispatch(actions.api.data.reset({ endpoint: api.endpoints.projectsHomeGraph })),
        updateCategories: (categories) =>
            dispatch(
                actions.api.data.post.request({
                    endpoint: api.endpoints.projectLegend,
                    data: categories,
                }),
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPanel);
