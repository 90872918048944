import BarChartIcon from '@mui/icons-material/BarChart';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PercentIcon from '@mui/icons-material/Percent';
import SyncIcon from '@mui/icons-material/Sync';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import React from 'react';

export const DEFAULT_BODY_HEIGHT = 168;
export const PAGE_HEADER_HEIGHT = 56;
export const URL_SAVE_INTERVAL = 5 * 1000;
export const VALIDATION_BUTTONS = '.validation-button-query';
export const TOAST_MESSAGE_POPUP = '.SnackbarItem-variantError';
export const VALIDATE_BUTTONS_TIMEOUT = 300;
export const VALIDATION_MAX_RETRY = 15;
export const DEFAULT_TABLE_VALUE = [{ title: 'New Table', data: [{ subject: '', query: [] }] }];
export const DELAY_BEFORE_ERROR = 1000;

export const DEFAULT_FIELDS = ['Level 1', 'Level 2', 'Level 3'];
export const THRESHOLD_FIELDS = ['Threshold'];
export const PROJECT_ENVIRONMENTS = ['testing', 'development'];

export const RATING_TYPES_OPTIONS = [
    {
        // The percentage of sub categories that reach the threshold
        value: 'Percentage',
        label: 'Percentage',
        icon: <PercentIcon />,
        fields: THRESHOLD_FIELDS,
        symbol: '%',
        steps: ['33', '66', '100'],
        subcategory: true,
    },
    {
        // The value that needs to be reached to validate each levels
        value: 'Min Value',
        label: 'Min Value',
        icon: <FormatListNumberedIcon />,
        fields: DEFAULT_FIELDS,
        symbol: '<',
        subcategory: false,
    },
    {
        // The number of sub categories that reach the threshold to access the next level
        value: 'Capped Percentage',
        label: 'Capped Percentage',
        icon: (
            <>
                <FilterListIcon />
                <PercentIcon />
            </>
        ),
        fields: [...THRESHOLD_FIELDS, ...DEFAULT_FIELDS],
        subcategory: true,
    },
    {
        // Find all the subcategories that reach the threshold, and add their market percentage value to the total
        value: 'Prevalence Percentage',
        label: 'Prevalence Percentage',
        icon: (
            <>
                <BarChartIcon />
                <PercentIcon />
            </>
        ),
        fields: THRESHOLD_FIELDS,
        symbol: '%',
        steps: ['30', '60', '90'],
        subcategory: true,
    },
];

export const VALUE_TYPES = [
    { value: 'Query Processing', label: 'Query', icon: <SyncIcon /> },
    { value: 'Hardcoded Value', label: 'Hardcoded', icon: <SyncDisabledIcon /> },
];

export const getEmptyRating = (type = undefined) => {
    const ratingType = RATING_TYPES_OPTIONS.find((rating) => rating.value === type) || RATING_TYPES_OPTIONS[1];
    const nbFields = ratingType.fields.length;
    return {
        type: '',
        additional: false,
        values: Array(nbFields).fill(''),
        devValues: Array(nbFields).fill(''),
        testValues: Array(nbFields).fill(''),
    };
};

export const isHardcodedValue = (value) => value === VALUE_TYPES[1].value;

export const getRatingType = (row) => {
    const actualType = RATING_TYPES_OPTIONS.find((rating) => rating.value === row?.rating.type);

    if (row?.subcategories?.length) {
        if (actualType?.subcategory) {
            return actualType.value;
        }
        return RATING_TYPES_OPTIONS[0].value;
    }

    return actualType?.value || RATING_TYPES_OPTIONS[1].value;
};
